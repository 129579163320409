<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: octubre 2023                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <v-main style="padding: 0 1rem !important;">

        <!-- filtros de busqueda y boton para registrar un médico -->
        <div class="d-flex pt-3">
            <v-text-field class="me-2" outlined dense hide-details label="Documento"
                v-model="buscadorDocumento" @keypress="validarLetra" maxlength="12" @input="listarMedicos()">
            </v-text-field>

            <v-text-field class="filtros me-2" outlined dense hide-details label="Nombre"
                v-model="buscadorNombre" @keypress="validarSoloLetra" @input="listarMedicos()">
            </v-text-field>

            <v-text-field class="me-2" outlined dense hide-details label="Apellido"
                v-model="buscadorApellido" @keypress="validarSoloLetra" @input="listarMedicos()">
            </v-text-field>

            <v-text-field class="filtros me-2" outlined dense hide-details label="Especialidad"
                v-model="buscadorEspecialidad" @input="listarMedicos()">
            </v-text-field>

            <v-tooltip left color="success">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small class="ms-2" fab color="success" v-bind="attrs" v-on="on" 
                        @mousedown.prevent="dialog = true">
                        <v-icon> add </v-icon>
                    </v-btn>
                </template>
                <span>Agregar</span>
            </v-tooltip>
        </div>

        <!-- dialogo para registrar y editar un médico -->
        <v-dialog v-model="dialog" persistent transition="dialog-bottom-transition" max-width="45.5rem">
            <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6">{{ tituloDialog }}</span>
                </v-card-title>

                <!-- formulario para registrar y editar un médico -->
                <v-card-text style="padding-top: 0.7rem;">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <v-form class="mt-3" lazy-validation>

                            <!-- Campos para el tipo de documento, número de documento y registro médico -->
                            <div class="d-flex mt-3 formulario-flex">
                                <div class="d-flex align-items-center me-2">
                                    <validation-provider v-slot="{ errors }" :rules="{ required: true, }" ref="tiposDeDocumento">
                                        <v-select id="tipoDocumento" v-model="registrarMedico.tipoDocumento" :items="listaDeTiposDocumento" outlined  
                                            :menu-props="{offsetY:true, maxWidth: 248}" :error-messages="errors" style="width: 15.5rem" :disabled="disabled == 1"
                                            dense label="Tipo de documento" @click="listarTiposDeDocumentos()" @input="validarDocumento()">
                                        </v-select>
                                    </validation-provider>
                                </div>
                                <div class="d-flex align-items-center me-2">
                                    <validation-provider v-slot="{ errors }" :rules="{ required: true, min: 6, ceroAndLetter: true }" ref="numeroDocumento">
                                        <v-text-field outlined id="numeroDocumento" v-model="registrarMedico.numeroDocumento" maxlength="12"
                                            :error-messages="documentoExistente || errors" style="width: 13rem" :disabled="disabled == 1"
                                            dense label="Número de documento" @input="validarDocumento()" @keypress="validarLetra">
                                        </v-text-field>
                                    </validation-provider>
                                </div>
                                <div class="d-flex align-items-center">
                                    <validation-provider ref="registroMedico" v-slot="{ errors }" :rules="{ registro: 5, registroMedicoRules: true, required: true }">
                                        <v-text-field outlined id="registroMedico" v-model="registrarMedico.registroMedico" maxlength="13" 
                                            dense :error-messages="registroExistente || errors" style="width: 13rem" :disabled="registrarMedico.numeroDocumento === '' || disabled == 1" 
                                            label="Registro médico" @input="validarRegistro() || convertirAMayusculas('registroMedico')" @keypress="validarLetra" >
                                        </v-text-field>
                                    </validation-provider>
                                </div>
                            </div>

                            <!-- Campos para los nombres  y apellidos del médico -->
                            <div class="d-flex formulario-flex">
                                <div class="d-flex align-items-center me-2">
                                    <validation-provider v-slot="{ errors }" :rules="{ required: true, }">
                                        <v-text-field outlined id="nombre" v-model="registrarMedico.nombre"  
                                            :error-messages="errors" dense style="width: 21rem" label="Nombres" 
                                            @keypress="validarSoloLetra" @input="convertirAMayusculas('nombre')">
                                        </v-text-field>
                                    </validation-provider>
                                </div>
                                <div class="d-flex align-items-center me-2">
                                    <validation-provider v-slot="{ errors }" :rules="{ required: true, }">
                                        <v-text-field outlined id="apellido" v-model="registrarMedico.apellido" 
                                            :error-messages="errors" dense style="width: 21rem" label="Apellidos"  
                                            @keypress="validarSoloLetra" @input="convertirAMayusculas('apellido')">
                                        </v-text-field>
                                    </validation-provider>
                                </div>
                            </div>

                            <!-- Campos para el correo y la fecha de nacimiento del médico -->
                            <div class="d-flex formulario-flex">
                                <div class="d-flex align-items-center me-2">
                                    <v-dialog ref="menu" v-model="menu" persistent width="290px" transition="scale-transition">
                                        <template v-slot:activator="{ on, attrs }">
                                            <validation-provider v-slot="{ errors }" :rules="{ required: true }" style="width: 21rem">
                                                <v-text-field v-model="registrarMedico.fechaNacimiento" label="Fecha de nacimiento" 
                                                    :error-messages="errors" readonly v-bind="attrs" v-on="on" dense outlined>
                                                </v-text-field>
                                            </validation-provider>
                                        </template>
                                        <v-date-picker v-model="registrarMedico.fechaNacimiento" scrollable
                                            :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                            min="1950-01-01" locale="es-co" :first-day-of-week="1">
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="menu = false">Cerrar</v-btn>
                                            <v-btn text color="primary" :disabled="registrarMedico.fechaNacimiento === ''" @click="save">OK</v-btn>
                                        </v-date-picker>
                                    </v-dialog>
                                </div>
                                <div class="d-flex align-items-center me-2">
                                    <validation-provider v-slot="{ errors }" :rules="{ required: true, validEmail: true }" style="width: 21rem">
                                        <v-text-field v-model="registrarMedico.email" label="Correo" dense outlined :error-messages="errors">
                                        </v-text-field>
                                    </validation-provider>
                                </div>
                            </div>

                            <!-- Campo para buscar y elegir una especialidad -->
                            <div>
                                <validation-provider ref="especialidades" v-slot="{ errors }" :rules="{ required: true, especialidadActiva: true }" style="max-width: 100%;">
                                    <v-autocomplete v-model="registrarMedico.especialidad" label="Especialidad" dense outlined :error-messages="errors" 
                                        :items="listaDeEspecialidades" :menu-props="{ maxHeight: 161, offsetY: true, maxWidth: 691 }"
                                        no-data-text="No se encontraron resultados.">
                                    </v-autocomplete>
                                </validation-provider>
                            </div>

                            <!-- Botones de cerrar, guardar y actualizar que se activan cuando se abre el dialogo para registrar un médico en base de datos-->
                            <div class="d-flex justify-end">
                                <v-btn color="error" text @click="reset()">
                                    CERRAR
                                </v-btn>
                                <v-btn v-if="!editar" depressed @click="guardarMedico()" :disabled="validacionActivada || desactivar || invalid" color="success">
                                    {{ textoBoton }}
                                </v-btn>
                                <v-btn v-else depressed @click="actualizarMedico()" :disabled="desactivar || invalid" color="success">
                                    {{ textoBoton }}
                                </v-btn>
                            </div>
                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Tabla que muestra la lista de los médicos registrados en base de datos -->
        <v-data-table :loading="loading" fixed-header :headers="headersRegistros" :items="listaDeMedicos"
            :page.sync="pagina" :items-per-page.sync="itemsPorPagina" :server-items-length="totalPaginas" class="elevation mt-4"
            height="66vh" :footer-props="footerPropslistaMedicos">
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" v-bind:key="item.id">
                        <td class="text-center">{{ item.tipoDocumento }}</td>
                        <td>{{ item.numeroDocumento }}</td>
                        <td>{{ item.nombres }} {{ item.apellidos }}</td>
                        <td>{{ item.registroMedico }}</td>
                        <td>{{ item.descripcion }}</td>
                        <td>
                            <v-icon :color="item.eliminado === false ? 'success' : 'error'"> 
                                {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
                            </v-icon>
                            {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
                        </td>
                        <td class="text-center">
                            <v-icon color="orange" @click="editarMedico(item)">border_color</v-icon>
                            <v-btn icon @click="abrirDialogoEstado(item)">
                                <v-icon :color="item.eliminado === false ? 'error' : 'success'">
                                    {{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}
                                </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:footer.page-text="items">
                {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
        </v-data-table>

        <!-- dialogo para cambiar el estado -->
        <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="22.7rem" persistent>
            <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6">{{ mensajeEstado }}</span>
                </v-card-title>
                <v-card-text>
                    <div class="d-flex justify-end" style="padding-top: 1.3rem">
                        <v-btn color="error" text @click="dialogoCambiarEstado = false">No</v-btn>
                        <v-btn color="success" depressed text :disabled="desactivar" @click="cambiarEstado()">Si</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-main>

</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapMutations, mapState } from 'vuex';
import { Role } from '@/router/role.js';
import { required, min, email } from 'vee-validate/dist/rules';

extend('required', {
    ...required,
    message: 'Este campo es requerido'
})
extend('min', {
    ...min,
    message: 'Mínimo 6 dígitos'
})
extend('registro', {
    ...min,
    message: 'Mínimo 5 caracteres'
})
extend('ceroAndLetter', {
  validate: value => {
    const soloCeros = /^\d+$/.test(value) && parseInt(value) === 0;
    const contieneLetras = /[a-zA-Z]/.test(value);
    if (!soloCeros || contieneLetras) {
        return true;
    }
    return false;
  },
  message: 'Formato incorrecto.'
})
let estadoEspecialidad = null;
extend('especialidadActiva', {
    validate: (value) => {
        if (value && estadoEspecialidad === true) {
            return false;
        }
        return true; 
    },
    message: 'La especialidad está inactiva',
});
extend('validEmail', {
    ...email,
    message: 'El correo electrónico debe ser válido.'
})
let numeroDocumento = null;
let numeroRegistro = null;
extend('registroMedicoRules', {
    validate: (value) => {
        // Valida si el registro medico es igual al número de documento, si no valida que el formato del registro medico sea correcto.
        if (numeroDocumento === numeroRegistro) {
            return true;
        } else {
            const noSoloCerosConLetrasOGuiones = /^(?!0+[a-zA-Z-]|.*[a-zA-Z-]0+$)(?![a-zA-Z]+-|-[a-zA-Z]+$)[0-9a-zA-Z-]*$/.test(value);
            const alMenosDosNumerosDiferentes = /^(?!([0-9])\1*$)[0-9a-zA-Z-]*$/.test(value);
            const noMasTresLetras = /^(?!.*[a-zA-Z].*[a-zA-Z].*[a-zA-Z].*[a-zA-Z].*)[0-9a-zA-Z-]*$/.test(value);
            const noMasTresGuiones = /^(?!.*-.*-.*-.*-)[0-9a-zA-Z-]*$/.test(value);
            const noMasCuatroCeros = /^(?!.*0.*0.*0.*0.*0.*)[0-9a-zA-Z-]*$/.test(value);
            const soloNumeros = /^[0-9a-zA-Z-]*$/.test(value);

            // Combina las validaciones según tus necesidades específicas
            const isValid = noSoloCerosConLetrasOGuiones && alMenosDosNumerosDiferentes && noMasTresLetras && noMasTresGuiones && noMasCuatroCeros && soloNumeros;

            return isValid || !value;
        }
    },
    message: 'Formato inválido',
});

export default {
    name: 'Medicos',
    components: {
        ValidationObserver,
        ValidationProvider,
    },

    data() {
        return {
            roles: Role,
            userRoles: {},
            usuario: '',
            buscadorDocumento: '',
            buscadorNombre: '',
            buscadorApellido: '',
            buscadorEspecialidad: '',
            dialog: false,
            tituloDialog: 'Registrar médico',
            registrarMedico: {
                tipoDocumento: '',
                numeroDocumento: '',
                nombre: '',
                apellido: '',
                registroMedico: '',
                especialidad: '',
                fechaNacimiento: '',
                email: '',
            },
            eliminado: false,
            listaDeTiposDocumento: [],
            documentoActualizado: '',
            listaDeEspecialidades: [],
            especialidadActualizada: null,
            mostrarLista: false,
            loading: false,
            headersRegistros: [
                { text: "TIPO", align: "center", sortable: false, width: "2rem" },
                { text: "DOCUMENTO", sortable: false, width: "4rem" },
                { text: "NOMBRE", sortable: false, width: "12rem" },
                { text: "REGISTRO MÉDICO", align: "center", sortable: false, width: "7rem" },
                { text: "ESPECIALIDAD", sortable: false, width: "12rem" },
                { text: "ESTADO", sortable: false, width: "7rem" },
                { text: "ACCIONES", align: "center", sortable: false, width: "7rem" },
            ],
            listaDeMedicos: [],
            pagina: 1,
            itemsPorPagina: 10,
            totalPaginas: 0,
            footerPropslistaMedicos: {
                'items-per-page-options': [10, 20, 30, 40, 50],
                'items-per-page-text': 'Items por página:',
                'show-current-page': true,
                'show-first-last-page': true,
            },
            medicoSeleccionado: {},
            dialogoCambiarEstado: false,
            mensajeEstado: '',
            textoBoton: "GUARDAR",
            editar: false,
            disabled: 0,
            documentoExistente: '',
            registroExistente: '',
            validacionActivada: false,
            numeroDocumento: null,
            numeroRegistro: null,
            menu: false,
            estadoEspecialidad: null,
            desactivar: false,
        }
    },

    // Se inicializa la variable userRoles con los roles del usuario autenticado
    created() {
        this.userRoles = this.auth.roles;
        this.listarMedicos();
    },

    computed: {
        ...mapState(["auth", "notify", "busy", "enterprise"]),
    },

    watch: {
        pagina: function () {
            this.listarMedicos();
        },
        itemsPorPagina: function () {
            this.pagina = 1;
            this.listarMedicos();
        },
        'buscadorDocumento': function () {
            this.pagina = 1;
            this.listarMedicos();
        },
        'buscadorNombre': function () {
            this.pagina = 1;
            this.listarMedicos();
        },
        'buscadorApellido': function () {
            this.pagina = 1;
            this.listarMedicos();
        },
        'buscadorEspecialidad': function () {
            this.pagina = 1;
            this.listarMedicos();
        },
        'registrarMedico.numeroDocumento' : function (documento) {
            numeroDocumento = documento;
        },
        'registrarMedico.registroMedico': function (registro) {
            numeroRegistro =  registro
        },
        'registrarMedico.especialidad': function () {
            const especialidad = this.listaDeEspecialidades.find(p => p.value === this.registrarMedico.especialidad);
            estadoEspecialidad = especialidad ? especialidad.estado === true : false;
        },
        'dialog': function () {
            if (this.dialog === true) {
                this.listarEspecialidades(); 
            }
        },
        'dialogoCambiarEstado': function (nextValue) {
            if (nextValue === false) {
                setTimeout(() => {
                    this.desactivar = false;
                }, 500)
            }
        }
    },

    mounted() {
        this.usuario = this.auth.username.toUpperCase();
        this.listarEspecialidades();
    },

    methods: {
        ...mapMutations([
            "updateAuth",
            "hideNotify",
            "showSuccess",
            "showBusy",
            "hideBusy",
        ]),

        /**
         * Obtiene la lista de especialidades.
         * La lista se obtiene haciendo una solicitud HTTP al servidor y mapeando los datos de respuesta.
         * Cada especialidad se representa como un objeto con propiedades 'text', 'value' y 'estado'.
         * 'text' representa la presentación de la especialidad en la interfaz de usuario.
         * 'value' representa el identificador único de la especialidad.
         * 'estado' indica si la especialidad está marcada como eliminada en la base de datos.
         * En caso de error durante la solicitud, se registra un mensaje de error en la consola.
         */
        async listarEspecialidades() {
            try {
                const response = await this.$http.get(`msa-administration/especialidad/listAll`);
                this.listaDeEspecialidades = response.data.map(item => ({
                    text: `${item.idEspecialidad} - ${item.descripcionEspecialidad}`,
                    value: `${item.idEspecialidad}`,
                    estado: item.eliminado,
                }));
            } catch (error) {
                console.log(error);
            }
        },

        /**
         * Método para guardar la fecha seleccionada en el componente de selección de fecha.
         * @param {Date} date - La fecha seleccionada.
         */
        save(date) {
            // Se llama al método save del ref "menu" para guardar la fecha
            this.$refs.menu.save(date)
        },

        /**
         * Valida que solo se ingresen caracteres alfanuméricos (letras y números) en un campo de texto.
         *
         * @param event El evento de teclado que activa esta función.
         * @description Si se presiona una tecla que no sea una letra o número, evita que se registre en el campo.
         */
        validarLetra(event) {
            const teclaPresionada = event.key
            if (teclaPresionada.match(/[^0-9A-Za-z-]/)) {
                event.preventDefault();
            }
        },

        /**
         * Valida que solo se ingresen letras (alfabeto) y espacios en un campo de texto.
         *
         * @param event El evento de teclado que activa esta función.
         * @description Si se presiona una tecla que no sea una letra o espacio, evita que se registre en el campo.
         */
        validarSoloLetra(event) {
            const teclaPresionada = event.key
            if (teclaPresionada.match(/[^A-Za-záéíóúÁÉÍÓÚ ]/)) {
                event.preventDefault();
            }
        },

        /**
         * Convierte el texto en un campo de formulario a mayúsculas.
         *
         * @param campo El nombre del campo en el formulario que se va a convertir a mayúsculas.
         */
        convertirAMayusculas(campo) {
            this.registrarMedico[campo] = this.registrarMedico[campo].toUpperCase();
        },

        /**
         * Recupera una lista de tipos de documentos desde el servidor y la almacena en la propiedad
         * 'listaDeTiposDocumento' del componente.
         * @throws {Error} Si ocurre un error durante la solicitud al servidor.
         */
        async listarTiposDeDocumentos() {
            this.listaDeTiposDocumento = [];
            try{
                const response = await this.$http.get(`msa-administration/tipoDocumento/listar`);
                this.listaDeTiposDocumento = response.data.map(item => ({
                    text: `${item.descripcion}`,
                    value: item.tipoDocumento,
                    tipoDocumento: item.tipoDocumento,
                }));
            }catch(error) {
                console.log(error);
            }
        },

        /**
        * Obtiene la lista de médicos registrados mediante una solicitud HTTP GET al servidor.
        * Los registros se obtienen con paginación y se filtran según los parámetros especificados.
        * Se actualiza el número total de páginas.
        * Además, se controla el estado de carga durante la solicitud.
        */
        listarMedicos() {
            this.buscadorDocumento = this.buscadorDocumento.toUpperCase();
            this.buscadorApellido = this.buscadorApellido.toUpperCase();
            this.buscadorNombre = this.buscadorNombre.toUpperCase();
            this.buscadorEspecialidad = this.buscadorEspecialidad.toUpperCase();
            this.loading = true;
            this.$http.get(`msa-administration/medico/listar`, {
                params: {
                    page: this.pagina - 1,
                    size: this.itemsPorPagina,
                    documento: `${this.buscadorDocumento}`,
                    nombre: `${this.buscadorNombre.toLowerCase()}`,
                    apellido: `${this.buscadorApellido.toLowerCase()}`,
                    especialidad: `${this.buscadorEspecialidad.toLowerCase()}`
                },
            }).then( response => {
                this.listaDeMedicos = response.data.content;
                this.totalPaginas = response.data.totalElements;
                this.loading = false;
            }).catch(error => {
                console.log(error)
                this.loading = false;
            })
        },

        /**
         * Guarda la información de un nuevo médico en el servidor.
         * Crea un objeto con los datos del médico, incluyendo la fecha de creación actual,
         * y realiza una solicitud al servidor para registrar al médico. Luego, actualiza la lista
         * de médicos y restablece el formulario.
         */
        guardarMedico() {
            this.desactivar = true;
            const horaActual = new Date;
            const datos = {
                idMedico: null,
                tipoDocumento: {
                    tipoDocumento: this.registrarMedico.tipoDocumento
                },
                numeroDocumento: this.registrarMedico.numeroDocumento,
                nombres: this.registrarMedico.nombre,
                apellidos: this.registrarMedico.apellido,
                email: this.registrarMedico.email,
                fechaNacimiento: this.registrarMedico.fechaNacimiento,
                especialidad: {
                    idEspecialidad: this.registrarMedico.especialidad
                },
                registroMedico: this.registrarMedico.registroMedico,
                eliminado: this.eliminado,
                createDate: horaActual.toISOString(),
                createdby: this.usuario,
            }
            this.$http.post("msa-administration/medico/crear", datos)
            .then(() => {
                this.listarMedicos();
                this.reset();
            })
            .catch((error) => {
                console.log(error);
            })
        },

        /**
        * Abre el diálogo para cambiar el estado de un registro.
        * Establece la propiedad 'dialogoCambiarEstado' en true para mostrar el diálogo.
        * Guarda el registro seleccionado en la propiedad 'medicoSeleccionado'.
        * Este método se invoca cuando se desea cambiar el estado de un registro.
        * @param {object} item - El registro seleccionado.
        */
        abrirDialogoEstado(item) {
            this.dialogoCambiarEstado = true
            this.medicoSeleccionado = item;
            this.mensajeEstado = this.medicoSeleccionado.eliminado === false ? '¿Desea inactivar el médico?' : '¿Desea activar el médico?';
        },

        /**
        * Cambia el estado de un registro.
        * Actualiza el estado del registro seleccionado ('medicoSeleccionado') según la lógica definida.
        * Realiza una solicitud HTTP PUT al servidor para actualizar el estado del registro.
        * Después de la actualización exitosa, se registra el mensaje y se actualiza la lista de registros.
        */
        cambiarEstado() {
            this.desactivar = true;
            const horaActual = new Date;
            this.medicoSeleccionado.eliminado = (this.medicoSeleccionado.eliminado == false) ? true : false;
            const cambiarEstado = {
                numeroDocumento: this.medicoSeleccionado.numeroDocumento,
                idMedico: this.medicoSeleccionado.idMedico,
                eliminado: this.medicoSeleccionado.eliminado,
                lastmodifiedby: this.usuario,
                lastmodifieddate: horaActual,
            }
            this.$http
                .put(`msa-administration/medico/cambiarEstado`, cambiarEstado)
                .then(() => {
                    this.listarMedicos();
                    this.dialogoCambiarEstado = false;
                }).catch(error => {
                    console.log(error);
                });
        },

        /**
         * Prepara el formulario de edición con los datos del médico seleccionado y muestra la interfaz
         * para la actualización de datos del médico.
         * @param item Los datos del médico seleccionado.
         */
        async editarMedico(item) {
            // Copiar los datos del médico seleccionado al formulario de edición
            this.medicoSeleccionado = item;
            this.registrarMedico.numeroDocumento = item.numeroDocumento;
            this.registrarMedico.registroMedico = item.registroMedico;
            this.registrarMedico.nombre = item.nombres;
            this.registrarMedico.apellido = item.apellidos;
            this.registrarMedico.fechaNacimiento = item.fechaNacimiento;
            this.registrarMedico.email = item.email;
            await this.listarTiposDeDocumentos();
            this.registrarMedico.tipoDocumento = this.listaDeTiposDocumento.find(doc => doc.tipoDocumento === this.medicoSeleccionado.tipoDocumento);
            await this.listarEspecialidades();
            const especialidad = this.listaDeEspecialidades.find(p => p.value === item.idEspecialidad);
            this.registrarMedico.especialidad = especialidad ? especialidad.value : '';
            // Configurar los valores para la interfaz de edición de los datos del médico
            this.tituloDialog = 'Editar médico';
            this.textoBoton = 'ACTUALIZAR';
            this.dialog = true;
            this.editar = true;
            this.disabled = 1;
        },

        /**
         * Actualiza la información de un médico en el servidor con los datos del formulario de edición.
         * Crea un objeto con los datos actualizados del médico y realiza una solicitud al servidor para
         * actualizar al médico. Luego, actualiza la lista de médicos y restablece el formulario.
         */
        actualizarMedico() {
            this.desactivar = true;
            const horaActual = new Date;
            const documento = this.registrarMedico.tipoDocumento.value;
            const documentoNuevo = this.registrarMedico.tipoDocumento;
            if (documento != null) {
                this.documentoActualizado = documento;
            } else {
                this.documentoActualizado = documentoNuevo;
            }
            const cambiarEspecialidad = this.registrarMedico.especialidad;
            const especialidadPresente = this.medicoSeleccionado.idEspecilidad;
            if (cambiarEspecialidad !== '') {
                this.especialidadActualizada = cambiarEspecialidad;
            } else {
                this.especialidadActualizada = especialidadPresente;
            }
            const datos = {
                idMedico: this.medicoSeleccionado.idMedico,
                tipoDocumento: {
                    tipoDocumento: this.documentoActualizado
                },
                numeroDocumento: this.registrarMedico.numeroDocumento,
                nombres: this.registrarMedico.nombre,
                apellidos: this.registrarMedico.apellido,
                email: this.registrarMedico.email,
                fechaNacimiento: `${this.registrarMedico.fechaNacimiento}T00:00:00-05:00`,
                especialidad: {
                    idEspecialidad: this.especialidadActualizada
                },
                registroMedico: this.registrarMedico.registroMedico,
                eliminado: this.eliminado,
                createDate: horaActual.toISOString(),
                createdby: this.usuario,
                lastmodifieddate: horaActual.toISOString(),
                lastmodifiedby: this.usuario,
            }
            this.$http.put("msa-administration/medico/actualizar", datos)
            .then(() => {
                this.listarMedicos();
                this.reset();
            })
            .catch((error) => {
                console.log(error);
            })
        },

        /**
         * Valida en SQL y en PostgreSQL
         * Valida si el número de documento ingresado es único en función del tipo de documento.
         * Realiza una solicitud al servidor para verificar la unicidad del documento. Actualiza las propiedades
         * 'documentoExistente' y 'validacionActivada' en respuesta al resultado de la validación.
         */
        validarDocumento() {
            this.registrarMedico.numeroDocumento = this.registrarMedico.numeroDocumento.toUpperCase();
            if (this.registrarMedico.tipoDocumento !== '' && this.registrarMedico.numeroDocumento !=='') {
                this.$http.get(`msa-administration/medico/validarDocumento`, {
                    params: {
                        numeroDocumento: this.registrarMedico.numeroDocumento,
                        tipoDocumento: this.registrarMedico.tipoDocumento
                    }
                }).then((response) => {
                    if (response.data !== "El documento es valido") {
                        this.documentoExistente = "El documento ya existe";
                        this.validacionActivada = true;
                    } else {
                        this.documentoExistente = "";
                        this.validacionActivada = false;
                    }
                }).catch((error) => {
                    console.log(error);
                    this.documentoExistente = "Error en la validación del documento";
                    this.validacionActivada = true;
                })
            } else {
                this.documentoExistente = "";
                this.validacionActivada = false;
            }
        },

        /**
         * Valida en SQL y en PostgreSQL
         * Valida si el número de registro médico ingresado es único.
         * Realiza una solicitud al servidor para verificar la unicidad del registro médico. Actualiza las propiedades
         * 'registroExistente' y 'validacionActivada' en respuesta al resultado de la validación.
         */
        validarRegistro() {
            this.registrarMedico.registroMedico = this.registrarMedico.registroMedico.toUpperCase();
            if (this.registrarMedico.registroMedico !== this.medicoSeleccionado.registroMedico) {
                if (this.registrarMedico.registroMedico !== '') {
                    this.$http.get(`msa-administration/medico/validarRegistro`, {
                        params: {
                            registroMedico: this.registrarMedico.registroMedico,
                        }
                    }).then((response) => {
                        if (response.data !== "Número de registro valido") {
                            this.registroExistente = "El registro ya existe";
                            this.validacionActivada = true;
                        } else {
                            this.registroExistente = "";
                            this.validacionActivada = false;
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.registroExistente = "Error validando el registro";
                        this.validacionActivada = true;
                    })
                } else {
                    this.registroExistente = "";
                    this.validacionActivada = false;
                }
            } else {
                this.registroExistente = "";
                this.validacionActivada = false;
            }
        },
        
        /**
        * Restablece los valores de los campos y las variables relacionadas al médico.
        * Establece los valores predeterminados y cierra los cuadros de diálogo abiertos.
        * Reinicia el estado de edición y activación de campos.
        * Reinicia el estado del componente de observación (refs.observer).
        */
        reset() {
            this.dialog = false;
            this.registrarMedico.tipoDocumento = '';
            this.registrarMedico.numeroDocumento = '';
            this.registrarMedico.nombre = '';
            this.registrarMedico.apellido = '';
            this.registrarMedico.registroMedico = '';
            this.registrarMedico.especialidad = '';
            this.registrarMedico.fechaNacimiento = '';
            this.registrarMedico.email = '';
            this.listaDeTiposDocumento = [];
            this.textoBoton = 'GUARDAR';
            this.tituloDialog = 'Registrar médico';
            this.editar = false;
            this.documentoActualizado = '';
            this.especialidadActualizada = null;
            this.mostrarLista = false,
            this.medicoSeleccionado = {};
            this.documentoExistente = '';
            this.registroExistente = '';
            this.validacionActivada = false;
            this.disabled = 0;
            this.$refs.observer?.reset();
            this.desactivar = false;
        },
    },
}
</script>


<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.fondoDialog {
  background-color: #1867c0;
  color: white;
}

.formulario-flex {
  flex-wrap: nowrap;
}

@media (max-width: 700px) {
  .formulario-flex {
    flex-wrap: wrap !important;
  }
}

::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

::v-deep .elevation .v-data-footer {
  width: 100%;
}

::v-deep .elevation .v-data-footer__select .v-select {
  margin: 5px;
  margin-left: 10px;
}

.resultadoLista {
    max-height: 12rem;
    overflow-y: auto;
    z-index: 999;
    width: 100%;
}

</style>